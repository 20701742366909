import React from 'react';

import styles from './product-design-full-product.module.scss';

const ProductDesignFullProduct = (props) => {
	const { icon,iconMobile, title, description, items } = props;

	return (
		<div className={styles.container}>
			<div className={styles.imageContainer}>
				<img className={styles.image} src={icon} alt="" />
			</div>

			<div className={styles.contentContainer}>
				<h1 className={styles.title}>{title}</h1>
				<p className={styles.description}>{description}</p>

				{items && items.length > 0 && (
					<ul>
						{items.map((item, index) => (
							<li key={index}>
								<h2 className={styles.listTitle}>{item.title}</h2>
								<p className={styles.listDescription}>{item.description}</p>
							</li>
						))}
					</ul>
				)}
			</div>

			<div className={styles.imageContainerMobile}>
				<img className={styles.imageMobile} src={iconMobile} alt="" />
			</div>
		</div>
	);
};

export default ProductDesignFullProduct;
