import React from 'react';
import styles from './how-it-works-full-prod.module.scss';

const HowItWorksFullProduct = (props) => {
	const { sectionTitle, title, subtitle, description, slider } = props;

	return (
		<div className={styles.container}>
			<div className={styles.howItWorks}>
				<h4 className={styles.sectionTitle}>{sectionTitle}</h4>
				<h1 className={styles.title}>{title}</h1>
				<h2 className={styles.subtitle}>{subtitle}</h2>
				<p className={styles.description}>{description}</p>

				<div className={styles.sliderContainer}>
					<img className={styles.slider} src={slider} alt="" />
					<p className={styles.sliderTitle}>Scroll for more content</p>
				</div>
			</div>

			<div className={styles.productStrategy}>
				<div className={styles.titleContainerProductStrategy}>
					<h2 className={styles.titleStrategy}>Product Strategy</h2>
				</div>

				<div className={styles.strategyContainer}>
					<div className={styles.discovery}>
						<h1 className={styles.titleCard}>Discovery and validation</h1>

						<div className={styles.figuresDiscovery}>
							<div className={styles.clientNeeds}>
								<label>Client Needs</label>
								<img className={styles.triangle} src="/cms/assets/product-gray-triangle.png" alt="" />
							</div>

							<div className={styles.marketStd}>
								<img className={styles.triangle} src="/cms/assets/product-purple-triangle.png" alt="" />
								<label>Market standards</label>
							</div>

							<div className={styles.userNeeds}>
								<img className={styles.triangle} src="/cms/assets/product-blue-triangle.png" alt="" />
								<label>User Needs</label>
							</div>
						</div>

						<div className={styles.footerCardProductStrategy}>
							<p className={styles.footerCardDescription}>
								Gathering information and valuable inputs around the opportunity:
								<b>Market fit, audience, and potential users.</b>
							</p>
						</div>
					</div>

					<div className={styles.separator}></div>

					<div className={styles.creating}>
						<h1 className={styles.titleCard}>Creating and validating hypotheses</h1>

						<div className={styles.figuresCreating}>
							<p>Hypotheses</p>
							<img className={styles.triangle} src="/cms/assets/product-blue-triangle.png" alt="" />
							<img className={styles.triangle} src="/cms/assets/product-purple-triangle.png" alt="" />
							<img className={styles.triangle} src="/cms/assets/product-gray-triangle.png" alt="" />
						</div>

						<div className={styles.footerCardProductStrategy}>
							<p className={styles.footerCardDescription}>
								Organizing and validating the inputs that were gathered during the previous stage
							</p>
						</div>
					</div>
				</div>
			</div>

			{/* Design and Development */}
			<div className={styles.designAndDev}>
				<div className={styles.titleContainerDesign}>
					<h2 className={styles.titleDesign}>Design & Development</h2>
				</div>

				<div className={styles.designAndDevContainer}>
					<div className={styles.design}>
						<h1 className={styles.titleCard}>Product Design</h1>

						<div className={styles.figuresProductDesign}>
							<div className={styles.describing}>
								<div className={`${styles.circle} ${styles.describingCircle}`}></div>

								<h4 className={styles.figuresTitle}>Describing</h4>
								<div className={styles.figuresItems}>
									<img
										className={styles.figuresImages}
										src="/cms/assets/product-describing1.png"
										alt=""
									/>
									<img
										className={styles.figuresImages}
										src="/cms/assets/product-describing2.png"
										alt=""
									/>
								</div>
								<h4 className={styles.figuresImagesDescription}>Ideal product</h4>
							</div>

							<div className={styles.describing}>
								<div className={`${styles.circle} ${styles.circleScoping}`}></div>

								<h4 className={styles.figuresTitle}>Scoping</h4>
								<div className={styles.figuresItems}>
									<img
										className={styles.figuresImages}
										src="/cms/assets/product-scoping.png"
										alt=""
									/>
									<img
										className={styles.figuresImages}
										src="/cms/assets/product-scoping.png"
										alt=""
									/>
								</div>
								<h4 className={styles.figuresImagesDescription}>Mvp</h4>
							</div>

							<div className={styles.describing}>
								<div className={`${styles.circle} ${styles.circleDesign}`}></div>

								<h4 className={styles.figuresTitle}>Designin the UX and VD</h4>

								<div className={styles.figuresItems}>
									<img
										className={styles.figuresImages}
										src="/cms/assets/product-user-stories.png"
										alt=""
									/>
									<img
										className={styles.figuresImages}
										src="/cms/assets/product-user-stories.png"
										alt=""
									/>
								</div>
								<h4 className={styles.figuresImagesDescription}>User stories estimated sized</h4>
							</div>
						</div>
						<div className={styles.footerCardProductDesign}>
							<p className={styles.footerCardDescription}>
								Scoping the ideal product and corresponding MVP, taking the validate hypotheses as the
								primary input.
							</p>
						</div>
					</div>
					<div className={styles.separator}></div>

					<div className={styles.dev}>
						<h1 className={styles.titleCard}>Product Development</h1>

						<div className={styles.figuresProductDev}>
							<div className={styles.firstSprint}>
								<h4 className={`${styles.figuresTitle} ${styles.titlePadding}`}>1st Sprint</h4>
								<img className={styles.figuresImages} src="/cms/assets/product-1st-sprint.png" alt="" />
							</div>

							<div className={styles.secondSprint}>
								<h4 className={`${styles.figuresTitle} ${styles.titlePadding}`}>2nd Sprint</h4>

								<img
									className={styles.figuresImages}
									src="/cms/assets/product-2nd-sprint1.png"
									alt=""
								/>
								<img className={styles.sprint2} src="/cms/assets/product-2nd-sprint2.png" alt="" />
							</div>
						</div>
						<div className={`${styles.footerCardProductDesign} ${styles.footerCardPadding}`}>
							<p className={styles.footerCardDescription}>User stories delivered</p>
						</div>
					</div>
				</div>
			</div>

			<div className={styles.growthAndOpt}>
				<div className={styles.titleContainerGrowth}>
					<h2 className={styles.titleGrowth}>Growth & Optimization</h2>
				</div>

				<div className={styles.growthContainer}>
					<h1 className={styles.titleCard}>Product Optimization</h1>
					<div className={styles.figuresOpt}>
						<h4 className={`${styles.figuresTitle} ${styles.titlePadding}`}>Growth & Optimization</h4>
						<img className={styles.figuresImages} src="/cms/assets/product-growth&opt.png" alt="" />
					</div>
					<div className={`${styles.footerCardProductOptimization} ${styles.footerCardPadding}`}>
						<p className={styles.footerCardDescription}>Optimized product</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HowItWorksFullProduct;
