import React from 'react';
import IconCard from '../IconCard';
import styles from './how-we-do-it-full-product.module.scss';

import Button from '@components/button';

const HowWeDoItFullProduct = (props) => {
	const { title, subtitle, items, finalText, buttonText, link } = props;

	return (
		<div className={styles.container}>
			<h1 className={styles.title}>{title}</h1>
			<p className={styles.subtitle}>{subtitle}</p>
			<div className={styles.featuresCards}>
				{items.map((item) => {
					return <IconCard {...item} />;
				})}
			</div>

			<p className={styles.finalText}>{finalText}</p>

			<div className={styles.buttonContainer}>	
				<Button filled link={link}  className={styles.button}>
					{buttonText}
				</Button>
			</div>	

		</div>
	);
};

export default HowWeDoItFullProduct;
