import React from 'react';
import IconCard from '../IconCard';
import styles from './application-full-product.module.scss';

const ApplicationFullProduct = (props) => {
	const { title, description, items, customMargin } = props;

	const containerClass = customMargin ? styles.containerCustom : styles.container;

	return (
		<div className={containerClass}>
			<div className={styles.customTitleContainer}>
				<h1 className={styles.customTitle}>We do product strategy development: </h1>
				<p className={styles.customTitle2}>
					User research, strategy, and product roadmap so you can launch a compelling product that connects
					with your users and fulfills your mission.
				</p>
			</div>

			<h1 className={styles.title}>{title}</h1>

			<p className={styles.description}>{description}</p>
			<div className={styles.featuresCards}>
				{items.map((item) => {
					return <IconCard {...item} />;
				})}
			</div>
		</div>
	);
};

export default ApplicationFullProduct;
