import BlogPosts from '@components/blog-posts';
import CarouselQuote from '@components/carousel-quote';
import CasesCarousel from '@components/cases-carousel';
import ContactUs from '@components/contact-us';
import Layout from '@components/layout';
import HeadingNearshore from '@components/heading-nearshore';
import ApplicationFullProduct from '@components/application-full-prod';
import ProductDesignFullProduct from '@components/product-design-full-prod';
import ProductGrowthFullProduct from '@components/product-growth-full-prod';
import OurKeysFullProduct from '@components/our-keys-full-prod';
import HowWeDoItFullProduct from '@components/how-we-do-it-full-prod';
import HowItWorksFullProduct from '@components/how-it-works-full-prod';
import HowItWorksFullProductMobile from '@components/how-it-works-full-prod-mobile';
import React from 'react';

import styles from './full-product.module.scss';

const FullProduct = (props) => {
	const { location, pageContext } = props;
	const casesCarousel = pageContext['casesCarousel'];
	const blogPosts = pageContext['blog-posts'];

	return (
		<Layout hideLocations={location} location={location} pageContext={pageContext}>
			<HeadingNearshore {...pageContext['we-are']} />

			<div className={styles.howItWorks}>
				<HowItWorksFullProduct {...pageContext['intro']} />
			</div>

			<div className={styles.howItWorksMobile}>
				<HowItWorksFullProductMobile {...pageContext['intro']} />
			</div>

			<ApplicationFullProduct {...pageContext['we-do']} />
			<ProductDesignFullProduct {...pageContext['product-design']} />
			<ApplicationFullProduct customMargin={true} {...pageContext['web-mobile']} />
			<ProductGrowthFullProduct {...pageContext['product-growth']} />
			<HowWeDoItFullProduct {...pageContext['how-we-do-it']} />
			<OurKeysFullProduct {...pageContext['our-keys']} />

			<CarouselQuote {...pageContext['reviews']} />
			{!!casesCarousel && !!casesCarousel.show && (
				<CasesCarousel slugs={casesCarousel.cases} title={casesCarousel.title} />
			)}
			{!!blogPosts && !!blogPosts.show && <BlogPosts posts={blogPosts.posts} title={blogPosts.title} />}
			<ContactUs id="contact-from-full-product" pathname={location.pathname} />
		</Layout>
	);
};

export default FullProduct;
