import React from 'react';

import styles from './our-keys-full-product.module.scss';

const OurKeysFullProduct = (props) => {
	const { title, items } = props;

	return (
		<div className={styles.container}>
			<h1 className={styles.title}>{title}</h1>
		
			<div className={styles.itemsGrid}>
				{items.map((item) => (
					<div className={styles.itemCard}>
						<img src={item.icon} alt="" />
						<h4>{item.title}</h4>
						<span>{item.description}</span>
						<i />
					</div>
				))}
			</div>
		</div>
	);
};

export default OurKeysFullProduct;
