import React, { useState } from 'react';
import styles from './how-it-works-full-prod-mobile.module.scss';

const HowItWorksFullProductMobile = (props) => {
	const { sectionTitle, subtitle, description } = props;

	const [isOpen, setIsOpen] = useState(true);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className={styles.container}>
			<div className={styles.howItWorks}>
				<h4 className={styles.sectionTitle}>{sectionTitle}</h4>
				<h2 className={styles.subtitle}>{subtitle}</h2>
				<p className={styles.description}>{description}</p>
			</div>

			<div onClick={toggleDropdown} className={styles.dropDown}>
				<div>
					<h1>{isOpen ? 'Product Strategy' : 'Product Strategy Framework'}</h1>
				</div>
				<div className={styles.dropDownIcon}>
					{isOpen ? (
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
							<path
								d="M18 15L12 9L6 15"
								stroke="black"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					) : (
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
							<path
								d="M6 9L12 15L18 9"
								stroke="black"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					)}
				</div>
			</div>

			{isOpen && (
				<div className={styles.figuresContainer}>
					<div className={styles.productStrategyContainer}>
						<h1 className={styles.titleFigures}>Discovery and validation</h1>
						<p className={styles.productStrategyDescription}>
							Gathering information and valuable inputs around the opportunity:
							<b>Market fit, audience, and potential users.</b>
						</p>
						<div className={styles.figuresDiscovery}>
							<div className={styles.clientNeeds}>
								<label className={styles.figuresText}>Client Needs</label>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="39"
									height="68"
									viewBox="0 0 39 68"
									fill="none"
								>
									<path
										d="M37.4336 32.5713C38.2807 33.3619 38.2807 34.7047 37.4336 35.4954L3.36467 67.2947C2.08624 68.488 -3.30377e-06 67.5814 -3.22733e-06 65.8327L-4.47342e-07 2.234C-3.709e-07 0.485212 2.08625 -0.421336 3.36467 0.771926L37.4336 32.5713Z"
										fill="#D9D9D9"
									/>
								</svg>
							</div>

							<div className={styles.marketStd}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="38"
									height="59"
									viewBox="0 0 38 59"
									fill="none"
								>
									<path
										d="M37.0867 27.9978C38.0729 28.7983 38.0729 30.3032 37.0867 31.1036L3.26038 58.5583C1.95317 59.6193 -2.87136e-06 58.689 -2.79777e-06 57.0055L-3.97599e-07 2.09599C-3.24007e-07 0.41241 1.95317 -0.517863 3.26038 0.543113L37.0867 27.9978Z"
										fill="#7D77B0"
									/>
								</svg>
								<label className={styles.figuresText}>Market standards</label>
							</div>

							<div className={styles.userNeeds}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="39"
									height="68"
									viewBox="0 0 39 68"
									fill="none"
								>
									<path
										d="M37.4336 32.5713C38.2807 33.3619 38.2807 34.7047 37.4336 35.4954L3.36467 67.2947C2.08624 68.488 -3.30377e-06 67.5814 -3.22733e-06 65.8327L-4.47342e-07 2.234C-3.709e-07 0.485212 2.08625 -0.421336 3.36467 0.771926L37.4336 32.5713Z"
										fill="#0E0276"
									/>
								</svg>
								<label className={styles.figuresText}>User Needs</label>
							</div>
						</div>

						<div className={styles.separatorContainer}>
							<div className={styles.separator}></div>
						</div>

						<div className={styles.figuresCreating}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="154"
								height="73"
								viewBox="0 0 154 73"
								fill="none"
							>
								<path
									d="M49.098 34.4497C50.0799 35.25 50.0799 36.75 49.098 37.5503L16.0136 64.5168C14.7071 65.5817 12.75 64.6521 12.75 62.9665L12.75 9.03345C12.75 7.34793 14.7071 6.41828 16.0136 7.48318L49.098 34.4497Z"
									fill="#0E0276"
								/>
								<path
									d="M101.046 35.4378C102.047 36.2385 102.047 37.7615 101.046 38.5622L67.2488 65.5798C65.9392 66.6267 64 65.6943 64 64.0176L64 9.98241C64 8.30573 65.9392 7.37328 67.2488 8.42022L101.046 35.4378Z"
									fill="#7D77B0"
								/>
								<path
									d="M152.061 34.9411C153.057 35.7417 153.057 37.2583 152.061 38.0589L119.003 64.6293C117.694 65.6812 115.75 64.7496 115.75 63.0704L115.75 9.92958C115.75 8.25044 117.694 7.31875 119.003 8.37071L152.061 34.9411Z"
									fill="#D9D9D9"
								/>
							</svg>
						</div>
						<p className={styles.figuresText}>Hypotheses</p>

						<div className={styles.footerSection}>
							<h1 className={styles.titleFigures}>Creating and validating hypotheses</h1>
							<p className={styles.footerText}>
								Organizing and validating the inputs that were gathered during the previous stage
							</p>
						</div>
					</div>

					<div className={styles.designAndDevelopmentContainer}>
						<div className={styles.headerDesignAndDev}>
							<h1>Design & Development</h1>
						</div>

						<div className={styles.figuresProductDesign}>
							<h1 className={styles.titleFigures}>Product Design</h1>
							<p className={styles.productStrategyDescription}>
								Organizing and validating the inputs that were gathered during the previous stage
							</p>

							<div className={styles.describing}>
								<div className={`${styles.circle} ${styles.describingCircle}`}></div>

								<h4 className={styles.figuresSubtitle}>Describing</h4>
								<div className={styles.figuresItems}>
									<img
										className={styles.figuresImages}
										src="/cms/assets/product-describing1.png"
										alt=""
									/>
									<img
										className={styles.figuresImages}
										src="/cms/assets/product-describing2.png"
										alt=""
									/>
								</div>
								<h4 className={styles.figuresImagesDescription}>Ideal product</h4>
							</div>

							<div className={styles.separatorContainer}>
								<div className={styles.separator}></div>
							</div>

							<div className={styles.describing}>
								<div className={`${styles.circle} ${styles.circleScoping}`}></div>

								<h4 className={styles.figuresSubtitle}>Scoping</h4>
								<div className={styles.figuresItems}>
									<img
										className={styles.figuresImages}
										src="/cms/assets/product-scoping.png"
										alt=""
									/>
									<img
										className={styles.figuresImages}
										src="/cms/assets/product-scoping.png"
										alt=""
									/>
								</div>
								<h4 className={styles.figuresImagesDescription}>Mvp</h4>
							</div>

							<div className={styles.separatorContainer}>
								<div className={styles.separator}></div>
							</div>

							<div className={styles.describing}>
								<div className={`${styles.circle} ${styles.circleDesign}`}></div>

								<h4 className={styles.figuresSubtitle}>Designin the UX and VD</h4>

								<div className={styles.figuresItems}>
									<img
										className={styles.figuresImages}
										src="/cms/assets/product-user-stories.png"
										alt=""
									/>
									<img
										className={styles.figuresImages}
										src="/cms/assets/product-user-stories.png"
										alt=""
									/>
								</div>
								<h4 className={styles.figuresImagesDescription}>User stories estimated sized</h4>
							</div>
						</div>

						<div className={styles.figuresProductDevelopment}>
							<h1 className={styles.titleFigures}>Product Development</h1>
							<p className={styles.productStrategyDescription}>User stories delivered</p>

							<div className={styles.firstSprint}>
								<h4 className={`${styles.figuresSubtitle} ${styles.titlePadding}`}>1st Sprint</h4>
								<img className={styles.figuresImages} src="/cms/assets/product-1st-sprint.png" alt="" />
							</div>

							<div className={styles.secondSprint}>
								<h4 className={`${styles.figuresSubtitle} ${styles.titlePadding}`}>2nd Sprint</h4>

								<img
									className={styles.figuresImages}
									src="/cms/assets/product-2nd-sprint1.png"
									alt=""
								/>
								<img
									className={`${styles.figureImages} ${styles.figurePadding}`}
									src="/cms/assets/product-2nd-sprint2.png"
									alt=""
								/>
							</div>
						</div>
					</div>

					<div className={styles.productOptimizationContainer}>
						<div className={styles.headerOptimization}>
							<h1>Product Optimization</h1>
						</div>
						<h1 className={styles.titleFigures}>Product Optimization</h1>
						<p className={styles.productStrategyDescription}>Optimized product.</p>
						<div className={styles.figuresOpt}>
							<img className={styles.figuresImagesOpt} src="/cms/assets/product-growth&opt.png" alt="" />
							<h4 className={`${styles.figuresImagesDescription} ${styles.titlePadding}`}>
								Growth & Optimization
							</h4>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default HowItWorksFullProductMobile;
